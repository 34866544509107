import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 7`}</strong></p>
    <p>{`5:00 to establish 3RM Back Squat`}</p>
    <p>{`then from 5:00-15:00,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`15-K2E’s (Scaled & Masters=hanging knee raise above parallel)`}</p>
    <p>{`10/Arm, alternating DB Snatch (RX=50/35, Scaled=45/30, Masters=40/25)`}</p>
    <p>{`5/Leg, alternating Pistol (Scaled & Masters=24/20″ step ups holding DB
used in the snatch)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Standards: `}</em></strong></p>
    <p><em parentName="p">{`RX K2E’s=Knees must touch elbows each rep`}</em></p>
    <p><em parentName="p">{`DB Snatch=Must alternate hands each rep.  Both sides of DB must touch
the floor.  Per new open rules db must exchange hands at or below eye
level.  DB’s cannot be dropped, must touch the ground on the last rep.`}</em></p>
    <p><em parentName="p">{`Pistols/Step Ups=Must alternate legs each rep.  Must get below parallel
and come to full extension with your opposite leg and foot in front of
your body (you can hold your foot) before touching the ground with your
opposite foot.  For Step Ups you must come to full extension at the top
of the box while holding the DB at your chest.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      